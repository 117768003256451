const  router = [
	{
		path: '/index',
		component: () => import('../../../pages/cash/Index.vue'),
		meta: {title: '收银'},
		name: 'cash'
	},
	
	{
		path: '/cash/pay',
		component: () => import('../../../pages/cash/Pay.vue'),
		meta: {title: '结算'},
		name: 'cash_pay'
	}
	
];

export default router