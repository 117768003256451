export default {
  state: {
	  gua:localStorage.getItem('gua') ?  JSON.parse(localStorage.getItem('gua')) : [],
  },
  mutations: { 
	  setGua(state,param){
		  state.gua = param;
		  localStorage.setItem('gua',JSON.stringify(param));
	  },
  }
}