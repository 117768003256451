import Vue from 'vue'
import VueRouter from 'vue-router'
Vue.use(VueRouter);

const routerList = [
	{
	    path: '/404',
	    component: () => import( '../../pages/404.vue'),
	    name:'nodata404',
	    meta: {title: '抱歉您找的页面失踪了'},
	},
	
	{
	    path: '/',
	    component: () => import( '../../pages/Passport.vue'),
	    name:'passport',
	    meta: {title: '登录'},
	},
	
];

const storeRouter = {
	    path: "/layout",
	    component: () => import('../../pages/Layout.vue'),
	    meta: {title: '收银台'},
	    name: 'layout',
		children:[
			{
			    path: '/refresh',
			    component: () => import( '../../pages/Refresh.vue'),
			    name:'refresh',
			    meta: {title: '刷新页面'},
			}
		]
}

const files = require.context('./routers', false, /\.js$/)

files.keys().forEach(key => {
    let menuLocal = files(key).default;
    for(var  a in menuLocal){
        storeRouter.children.push(menuLocal[a]);
    }
})
routerList.push(storeRouter);


const routerObj = new VueRouter({
    routes:routerList
});


routerObj.beforeEach((to, from, next) => { 
     if(to.name == null){
        next({
            path:'/404'
        });
     }else{
       document.title =  to.meta.title + ' - 基础储值会员卡收银台'
       next();
     }
})

export default routerObj;