const  router = [
	{
		path: '/member',
		component: () => import('../../../pages/member/Index.vue'),
		meta: {title: '会员'},
		name: 'member'
	},
	{
		path: '/member/detail',
		component: () => import('../../../pages/member/Detail.vue'),
		meta: {title: '会员详情'},
		name: 'member_detail'
	},
];

export default router