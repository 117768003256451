import {mapState} from 'vuex';

export const common = {
	
	computed:{
		...mapState({
			test:state => state.test.test,
			cashierCart:state=>state.cart.cashierCart,
			cashierCartIndex:state=>state.cart.cashierCartIndex,
			cashierMember:state=>state.member.cashierMember,
			gua:state=>state.gua.gua,
			
			//wzf新增
			cashierCartTabType:state=>state.cart.cashierCartTabType,
			cashierCartRecharge:state=>state.cart.cashierCartRecharge,
			cashierCartTaocan:state => state.cart.cashierCartTaocan,
		}),
		getSelectKey(){
			return this.$route.name;
		}
	},
	methods:{
		refreshPage(page){ // 比如弹出层操作后要跳转到某个页面就这样执行
			this.$router.push({path:'/refresh',query:{path:page}});
		},
		opeCashierCartTcUserPrice(){
			let cart=JSON.parse(JSON.stringify(this.cashierCart));
			//console.log('cart',cart)
			var info = cart[this.cashierCartIndex]?cart[this.cashierCartIndex]:{};
			if(info.fcUserList)
			{
				info.fcUserList.forEach((item,index)=>{
					item.num = info.num;
					var yejiticheng = item.yejiticheng?item.yejiticheng:'';
					if(yejiticheng)
					{
						yejiticheng.partner_amount  = item.price * item.num * yejiticheng.partner_rate / 100;
						if(yejiticheng.award_type == 1)
						{
							//固定金额
							yejiticheng.ticheng_amount = (parseFloat((yejiticheng.partner_rate /100) * yejiticheng.val * item.num).toFixed(2));
						}else{
							yejiticheng.ticheng_amount = parseFloat(parseFloat(yejiticheng.partner_amount * yejiticheng.val / 100).toFixed(2));
						}
						item.yejiticheng = yejiticheng;
					}
					var shougongfei = item.shougongfei?item.shougongfei:'';
					if(shougongfei)
					{
						shougongfei.partner_amount  = item.price * item.num * shougongfei.partner_rate / 100;
						if(shougongfei.award_type == 1)
						{
							//固定金额
							shougongfei.ticheng_amount = (parseFloat((shougongfei.partner_rate /100) * shougongfei.val * item.num).toFixed(2));
						}else{
							shougongfei.ticheng_amount = parseFloat(parseFloat(shougongfei.partner_amount * shougongfei.val / 100).toFixed(2));
						}
						item.shougongfei = shougongfei;
					}
				})
				cart[this.cashierCartIndex] = info;
			}
			console.log('执行 opeCashierCartTcUserPrice')
			this.$store.commit('setCashierCart',cart);
		},
		opeCashierCartTaocanTcUserPrice(){
			var cashierCartTaocan = JSON.parse(JSON.stringify(this.cashierCartTaocan));
			var info = cashierCartTaocan.list[cashierCartTaocan.selListIndex]?cashierCartTaocan.list[cashierCartTaocan.selListIndex]:{};
			if(info.fcUserList)
			{
				info.fcUserList.forEach((item,index)=>{
					item.num = info.num;
					var yejiticheng = item.yejiticheng?item.yejiticheng:'';
					if(yejiticheng)
					{
						yejiticheng.partner_amount  = item.price * item.num * yejiticheng.partner_rate / 100;
						if(yejiticheng.award_type == 1)
						{
							//固定金额
							yejiticheng.ticheng_amount = (parseFloat((yejiticheng.partner_rate /100) * yejiticheng.val * item.num).toFixed(2));
						}else{
							yejiticheng.ticheng_amount = parseFloat(parseFloat(yejiticheng.partner_amount * yejiticheng.val / 100).toFixed(2));
						}
						item.yejiticheng = yejiticheng;
					}
				})
				cashierCartTaocan.list[cashierCartTaocan.selListIndex] = info;
			}
			console.log('执行opeCashierCartTaocanTcUserPrice')
			this.$store.commit('setCashierCartTaocan',cashierCartTaocan);
		},
		opeCashierCartRechargeTcUserPrice(){
			var cashierCartRecharge = JSON.parse(JSON.stringify(this.cashierCartRecharge));
			if(cashierCartRecharge.fcUserList)
			{
				cashierCartRecharge.fcUserList.forEach((item,index)=>{
					item.price = cashierCartRecharge.recharge_price;
					item.num = 1;
					var yejiticheng = item.yejiticheng?item.yejiticheng:'';
					if(yejiticheng)
					{
						yejiticheng.partner_amount  = item.price * item.num * yejiticheng.partner_rate / 100;
						if(yejiticheng.award_type == 1)
						{
							//固定金额
							yejiticheng.ticheng_amount = (parseFloat((yejiticheng.partner_rate /100) * yejiticheng.val * item.num).toFixed(2));
						}else{
							yejiticheng.ticheng_amount = parseFloat(parseFloat(yejiticheng.partner_amount * yejiticheng.val / 100).toFixed(2));
						}
						item.yejiticheng = yejiticheng;
					}
				})
			}
			console.log('执行 opeCashierCartRechargeTcUserPrice')
			this.$store.commit('setCashierCartRecharge',cashierCartRecharge);
		}
	}
}