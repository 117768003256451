//import {common} from '../../../common/mixin/common.js';
export default {
  state: {

	

		cashierCart:localStorage.getItem('cashier-cart') ?  JSON.parse(localStorage.getItem('cashier-cart')) : [],
		cashierCartIndex: localStorage.getItem('cashier-cart-index') ? localStorage.getItem('cashier-cart-index') : -1,


		//购物车展示样式
		cashierCartTabType:localStorage.getItem('cashier-cart-tab-type') ?  localStorage.getItem('cashier-cart-tab-type') : 'goods', 

		//存储充值信息
		cashierCartRecharge:localStorage.getItem('cashier-cart-recharge') ?  JSON.parse(localStorage.getItem('cashier-cart-recharge')) : {},


		//存储套餐信息
		cashierCartTaocan:localStorage.getItem('cashier-cart-taocan') ?  
			JSON.parse(localStorage.getItem('cashier-cart-taocan')) : 
			{type:1,list:[],selListIndex:-1,buyList:[],selBuyListIndex:-1,zsGoodsListObj:{},zsXmListObj:{}},

  },
  mutations: { 
	  setCashierCart(state,param){
		  state.cashierCart = param;
		  localStorage.setItem('cashier-cart',JSON.stringify(param));
	  },
	  setCashierCartIndex(state,param){
		  state.cashierCartIndex=param;
		  localStorage.setItem('cashier-cart-index',param);
	  },
	  setCashierCartTabType(state,param){
		//console.log('setCashierCartTabType',123123)
			state.cashierCartTabType=param;
		  localStorage.setItem('cashier-cart-tab-type',param);
	  }, 
	  setCashierCartRecharge(state,param){
			state.cashierCartRecharge = param;
		  localStorage.setItem('cashier-cart-recharge',JSON.stringify(param));
	  },
	  setCashierCartTaocan(state,param){
			state.cashierCartTaocan = param;
			localStorage.setItem('cashier-cart-taocan',param?JSON.stringify(param):'');
		},
	  
  }
}