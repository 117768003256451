const  router = [
	{
		path: '/order',
		component: () => import('../../../pages/order/Index.vue'),
		meta: {title: '订单列表'},
		name: 'order'
	},
	{
		path: '/order/pay',
		component: () => import('../../../pages/order/Pay.vue'),
		meta: {title: '订单支付'},
		name: 'order_pay'
	},
	{
		path: '/order/detail',
		component: () => import('../../../pages/order/Detail.vue'),
		meta: {title: '订单详情'},
		name: 'order_detail'
	},
	{
		path: '/order/refund',
		component: () => import('../../../pages/order/refund.vue'),
		meta: {title: '退款'},
		name: 'order_refund'
	}
];

export default router