export default {
  state: {
	  cashierMember:localStorage.getItem('cashier-member') ?  JSON.parse(localStorage.getItem('cashier-member')) : {},
  },
  mutations: { 
	  setMember(state,param){
		  state.cashierMember = param;
		  localStorage.setItem('cashier-member',JSON.stringify(param));
	  }
  }
}